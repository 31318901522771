<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step
        :complete="step > 1"
        icon="mdi-account"
        step="1"
        editable
        >{{ __('Dados pessoais', 'contact') }}</v-stepper-step
      >

      <v-divider></v-divider>

      <v-stepper-step :complete="step > 2" step="2">{{
        __('Endereços', 'contact')
      }}</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1" editable>
        <contact-data-form
          @submit="handleSubmitStep1"
          :errors="errors"
          :submit_label="capitalize($tc('model.interface_next', 1))"
        ></contact-data-form>
      </v-stepper-content>

      <v-stepper-content step="2" editable>
        <contact-address-table
          @cancel="step = 1"
          @submit="handleSubmitStep2"
        ></contact-address-table>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import ContactDataForm from "./Contact";
import ContactAddressTable from "../tables/ContactAddressTable";
import i18n from '@/mixins/i18n';

export default {
  name: "new-contact-form",
  mixins: [i18n],
  components: { ContactDataForm, ContactAddressTable },
  data: () => ({
    step: 1,
    form: null,
    errors: {},
  }),
  methods: {
    handleSubmitStep1(form) {
      this.form = { ...form };
      this.step++;
    },
    async handleSubmitStep2(addresses) {
      this.form.addresses = addresses.map((address) => {
        address.country_area_id = address.country_area.id;
        address.city_id = address.city.id;
        address.city_area_id = address.city_area?.id;
        return address;
      });
      this.errors = {};

      this.$store.commit("setLoading", { loading: true });
      try {
        const response = await this.$http.post("/contacts", this.form);
        this.$store.commit("sendMessage", {
          text: this.__('Contato adicionado com sucesso', 'contact'),
        });
        this.$router.push("/contacts");
      } catch (error) {
        const status = error.response.status;
        if (status === 422) {
          this.step = 1;
          this.errors = error.response.data.errors;
        } else {
          this.$store.commit("sendMessage", {
            text: this.__('Falha ao carregar contato', 'contact'),
            color: "red",
          });
        }
      } finally {
        this.$store.commit("setLoading", { loading: false });
      }
    },
    capitalize: function (s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
};
</script>