<template>
  <div>
    <v-row>
      <v-col>
        <!-- <v-text-field
          @keypress.enter.prevent="getData()"
          placeholder="Filtrar moradas"
          append-icon="mdi-magnify"
        ></v-text-field>-->
      </v-col>
      <v-col class="d-flex align-center justify-end">
        <v-btn color="primary" outlined @click="handleClickNewMorada">{{
          __('Novo endereço', 'contact')
        }}</v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-list
      three-line
      style="max-height: 590px; min-height: 400px; overflow-y: auto"
    >
      <v-list-item v-if="items.length === 0 && !loading">
        <v-list-item-content>
          <v-list-item-title class="text-center grey--text">
            {{ __('Nenhum endereço encontrado', 'address') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        v-for="(item, i) in items"
        :key="i"
        @click="handleClickEditMorada(i)"
      >
        <v-list-item-avatar>
          <v-icon
            class="lighten-1 white--text"
            :class="{
              grey: !item.pivot.is_default,
              primary: item.pivot.is_default,
            }"
          >{{ item.pivot.is_default ? 'mdi-home-floor-1' : 'mdi-home' }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle v-if="item.pivot.is_default">{{ __('Endereço padrão', 'address') }}</v-list-item-subtitle>
          <v-list-item-title
            >{{ item.street }}, {{ item.number || "S/N" }}</v-list-item-title
          >
          <v-list-item-subtitle
            >{{ item.country_area.name }},
            {{
              item.city.name +
              (item.city_area && item.city_area.name
                ? `, ${item.city_area.name}`
                : "")
            }}</v-list-item-subtitle
          >
          <v-list-item-subtitle>{{ item.postal_code }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="d-flex flex-row">
          <v-tooltip bottom v-if="!item.pivot.is_default">
            <template v-slot:activator="{ on, attrs }"> 
              <v-btn @click.stop="makeAddressDefault(i)" icon>
                <v-icon 
                  size="22" 
                  color="grey lighten-1"
                  v-on="on"
                  v-bind="attrs"
                >
                mdi-home-floor-1
                </v-icon>
              </v-btn>
            </template>
            <span>{{ __('Tornar o endereço padrão', 'address') }}</span>
          </v-tooltip>
          <v-btn @click.stop="confirmDelete(i)" icon>
            <v-icon size="22" color="grey lighten-1"
              >mdi-delete</v-icon
            >
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-btn
        color="secondary"
        text
        @click="$emit('cancel')"
        v-if="!hide_cancel"
        >{{ cancel_label || $tc("model.interface_back", 1) }}</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="handleSubmit">{{
        submit_label || $tc("model.interface_create_button", 1)
      }}</v-btn>
    </v-card-actions>

    <v-dialog v-model="dialogdata" width="500px" persistent>
      <address-form
        :init="init"
        @cancel="handleCancelDialogData"
        @submit="handleSubmitlDialogData"
      ></address-form>
    </v-dialog>

    <confirm-action-dialog
      :confirm_action="confirmation"
      @change="confirmation = $event"
      @action="deleteItem()"
      :message="__('O endereço será removido permanentemente', 'address')"
    ></confirm-action-dialog>
  </div>
</template>

<script>
import AddressForm from "../forms/AddressForm";
import ConfirmActionDialog from "../ConfirmActionDialog";
import i18n from '@/mixins/i18n';

export default {
  name: "contact-address-table",
  props: ["contact_id", "submit_label", "cancel_label", "hide_cancel"],
  components: { AddressForm, ConfirmActionDialog },
  mixins: [i18n],
  data: () => ({
    items: [],
    loading: false,
    headers: [
      { text: "Distrito", value: "country_area.name" },
      { text: "Concelho", value: "city.name" },
      { text: "Freguesia", value: "city_area.name" },
      { text: "Morada", value: "street" },
    ],
    options: {},
    totalItems: 0,
    confirmation: false,
    delete_id: null,
    delete_loading: false,
    dialogdata: false,
    init: null,
    index: null,
  }),
  methods: {
    async getData() {
      this.items = [];
      this.loading = true;
      try {
        const response = await this.$http.get(
          `/addresses?contact_id=${this.contact_id}`
        );
        this.items = response.data;
        this.totalItems = this.items.length;
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    async deleteItem() {
      const id = this.delete_id;
      try {
        this.delete_loading = true;
        this.items.splice(id, 1);
        this.confirmation = false;
        this.$store.commit("sendMessage", {
          text: this.__('Excluído', 'address'),
        });
      } catch (e) {
      } finally {
        this.delete_loading = false;
      }
    },
    confirmDelete(id) {
      this.delete_id = id;
      this.confirmation = true;
    },
    makeAddressDefault(index) {
      this.items = this.items.map((rec, rec_index) => {
        if (rec_index === index) {
          return {
            ...rec,
            pivot: {
              is_default: true
            }
          }
        }

        return {
            ...rec,
            pivot: {
              is_default: false
            }
          }
      })
    },
    resetList() {
      this.items = [];
      this.totalItems = 0;
    },
    handleClickNewMorada() {
      this.dialogdata = true;
    },
    handleClickEditMorada(index) {
      this.index = index;
      this.init = this.items[index];
      this.dialogdata = true;
    },
    handleCancelDialogData() {
      this.init = null;
      this.dialogdata = false;
    },
    handleSubmitlDialogData(data) {
      if (this.init) {
        this.items[this.index] = data;
      } else {
        this.items.push({
          ...data,
          pivot: {
            is_default: this.items.length === 0 // Se não tiver nenhum endereço, tornar o primeiro padrão
          }
        });
      }
      this.dialogdata = false;
      this.init = null;
      this.index = null;
    },
    handleSubmit() {
      this.$emit("submit", this.items);
    },
  },
  created() {
    if (this.contact_id) {
      this.getData();
    }
  },
  watch: {
    contact_id: function (value) {
      if (value) {
        this.getData();
      } else {
        this.resetList();
      }
    },
  },
};
</script>