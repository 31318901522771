<template>
  <v-form @submit.prevent="handleSubmitForm" class="contact-form">
    <v-card :loading="form_loading">
      <v-card-text>
        <ValidationObserver ref="contact_form" v-slot="{ invalid }">
          <ValidationProvider
            name="name"
            rules="required|max:45"
            v-slot="{ errors }"
          >
            <v-text-field
              prepend-icon="mdi-account-circle"
              :label="__('Nome', 'contact')"
              name="name"
              type="text"
              :counter="45"
              v-model="form.name"
              :error-messages="errors"
              :disabled="form_loading"
            />
          </ValidationProvider>

          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="form.type"
                :prepend-icon="form.type == 'F' ? 'mdi-account' : 'mdi-domain'"
                :items="[
                  {
                    text: __('Pessoa física', 'contact'),
                    value: 'F',
                  },
                  {
                    text: __('Pessoa jurídica', 'contact'),
                    value: 'J',
                  },
                ]"
                :label="__('Tipo', 'contact')"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :prepend-icon="getGenderIcon(form.gender)"
                v-model="form.gender"
                :items="[
                  {
                    text: capitalize($tc('model.interface_uninformed')),
                    value: 'N/A',
                  },
                  {
                    text: __('Masculino', 'contact'),
                    value: 'M',
                  },
                  {
                    text: __('Feminino', 'contact'),
                    value: 'F',
                  },
                ]"
                :label="__('Gênero', 'contact')"
              ></v-select>
            </v-col>
          </v-row>

          <ValidationProvider
            name="email"
            rules="email|max:100"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="__('Email', 'contact')"
              name="email"
              type="email"
              prepend-icon="mdi-email"
              :counter="100"
              v-model="form.email"
              :error-messages="errors"
              :disabled="form_loading"
            />
          </ValidationProvider>
          <v-row>
            <v-col cols="12" sm="6">
              <ValidationProvider
                name="contact1"
                rules="required|length:11"
                v-slot="{ errors }"
              >
                <v-text-field
                  :label="`${__('Telefone principal', 'contact')}`"
                  name="contact1"
                  type="text"
                  prepend-icon="mdi-phone"
                  v-model="form.contact1"
                  :error-messages="errors"
                  :disabled="form_loading"
                  v-mask="'### ### ###'"
                  placeholder="123 456 789"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                :rules="`is_not:${form.contact1}|length:11`"
                v-slot="{ errors }"
              >
                <v-text-field
                  :label="`${__('Telefone secundário', 'contact')}`"
                  name="contact2"
                  type="text"
                  prepend-icon="mdi-phone"
                  v-model="form.contact2"
                  :error-messages="errors"
                  :disabled="form_loading"
                  v-mask="'### ### ###'"
                  placeholder="123 456 789"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <ValidationProvider
            name="fiscal_number"
            rules="length:9"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${__('Documento fiscal', 'contact')}`"
              prepend-icon="mdi-card-bulleted-outline"
              name="fiscal_number"
              type="text"
              v-model="form.fiscal_number"
              :error-messages="errors"
              :disabled="form_loading"
              v-mask="'#########'"
            />
          </ValidationProvider>
          <ValidationProvider name="iban" rules v-slot="{ errors }">
            <v-text-field
              :label="`${__('IBAN', 'contact')}`"
              prepend-icon="mdi-bank"
              name="iban"
              type="text"
              v-model="form.iban"
              :error-messages="errors"
              :disabled="form_loading"
              v-mask="'AA## #### #### ########### ##'"
            />
          </ValidationProvider>

          <ValidationProvider
            name="aditional_info"
            rules="max:255"
            v-slot="{ errors }"
          >
            <v-textarea
              name="aditional_info"
              counter="255"
              :label="`${__('Informação adicional', 'contact')}`"
              v-model="form.aditional_info"
              rows="4"
              prepend-icon="mdi-comment"
              :error-messages="errors"
              :disabled="form_loading"
            ></v-textarea>
          </ValidationProvider>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text type="submit" color="primary" :loading="form_submitting">{{
          submit_label
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import ContactAddressTable from "../tables/ContactAddressTable";
import i18n from '@/mixins/i18n';

export default {
  mixins: [i18n],
  directives: { mask },
  props: {
    id: Number,
    submit_label: {
      type: String,
      default: "Submeter",
    },
    errors: Object,
  },
  name: "contact-data-form",
  data: () => ({
    form: {
      type: "F",
      gender: "N/A",
      contact1: "",
    },
    form_loading: false,
    form_submitting: false,
    tab: 1,
    heigth: "400px",
  }),
  methods: {
    capitalize: function (s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    fetchContact(id) {
      return this.$http.get(`/contacts/${id}`);
    },
    storeContact(payload) {
      return this.$http.post(`/contacts`, payload);
    },
    updateContact(payload, id) {
      return this.$http.put(`/contacts/${id}`, payload);
    },
    async getContact(id) {
      this.form_loading = true;
      const response = await this.fetchContact(id);
      this.form = response.data;
      this.form_loading = false;
    },
    getGenderIcon(gender) {
      switch (gender) {
        case "M":
          return "mdi-gender-male";
        case "F":
          return "mdi-gender-female";
      }
    },
    resetForm() {
      this.form = {
        type: "F",
        gender: "N/A",
        contact1: "",
      };
      if (this.$refs.contact_form) this.$refs.contact_form.reset();
    },
    async handleSubmitForm() {
      const validation = await this.$refs.contact_form.validate();

      if (validation) {
        const payload = { ...this.form };

        this.form_loading = true;
        this.form_submitting = true;

        try {
          let response = null;
          let message = "Registro atualizado";
          this.$emit("submit", this.form);
        } catch (e) {
          this.$refs.contact_form.setErrors(e.response.data.errors);
          this.$store.commit("sendMessage", {
            text: `(${e.response.status}) ${e.response.data.message}`,
            color: "red",
          });
        } finally {
          this.form_loading = false;
          this.form_submitting = false;
        }
      }
    },
    handleCancel() {
      this.tab = null;
      this.resetForm();
      this.$emit("cancel");
    },
    getCardTitle() {
      return this.id ? this.form.name || "Contato" : "Novo Contato";
    },
  },
  created() {
    if (!this.id) {
      this.resetForm();
    } else {
      this.getContact(this.id);
    }
  },
  computed: {
    tabs: function (value) {
      return ["Dados", "Moradas"];
    },
  },
  watch: {
    id: function (value, old_value) {
      if (!value) {
        this.resetForm();
      } else {
        this.getContact(value);
      }
    },
    errors: function (value) {
      this.$refs.contact_form.setErrors(value);
    },
  },
};
</script>
